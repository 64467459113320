import React, { Component } from 'react'
import { DiscussionEmbed } from 'disqus-react'

class Disqus extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { postNode, siteMetadata } = this.props
    if (!siteMetadata.disqusShortname) {
      return null
    }
    const post = postNode.frontmatter
    const disqusConfig = {
        url: siteMetadata.url + postNode.fields.slug,
        identifier: postNode.id,
        title: post.title,
    };
    return (
      <DiscussionEmbed shortname={siteMetadata.disqusShortname} config={disqusConfig} />
    )
  }
}

export default Disqus
