import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import './style.scss'

class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const {
      title,
      date,
      layout,
      disable_comments,
      tags
    } = post.frontmatter;
    const tag_slugs = post.fields.tagSlugs

    let home_url, home_text;
    if (layout == "post_blog") {
        home_url = "/";
        home_text = "All Posts";
    }
    else if (layout == "post_art") {
        home_url = "/art/";
        home_text = "All Art Projects";
    }
    else {
        home_url = "/"
        home_text = `<TODO: need text and url for post layout '${layout}'>`;
    }

    const backButton = (
      <div>
        <Link className="post-single__home-button" to={home_url}>{home_text}</Link>
      </div>
    )

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tag_slugs &&
            tag_slugs.map((tag_slug, i) => (
              <li className="post-single__tags-list-item" key={tag_slug}>
                <Link to={tag_slug} className="post-single__tags-list-item-link">
                  {tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const commentsBlock = (
      <div id="comments">
        <Disqus postNode={post} siteMetadata={this.props.data.site.siteMetadata} />
      </div>
    )

    return (
      <div>
        {backButton}
        <div className="post-single">
          <div className="post-single__inner">
            <h1 className="post-single__title">{title}</h1>
            <div
              className="post-single__body"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <div className="post-single__date">
              <em>Published {moment(date).format('MMMM D, YYYY')}</em>
            </div>
          </div>
          <div className="post-single__footer">
            {tagsBlock}
            {disable_comments ? null : commentsBlock}
          </div>
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
